:root {
  --rosa: #a7115e;
  --green: #5b8c8d;
  --negro: #000000;
  --white: #fff;
  --font: "Ubuntu", sans-serif;
}
.rosa {
  color: var(--rosa);
}
.white {
  color: var(--white);
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font);
  background: url("https://s3-eu-south-2.ionoscloud.com/cursos/nhco-registro/img/fondo-top.png")
      top left no-repeat,
    url("https://s3-eu-south-2.ionoscloud.com/cursos/nhco-registro/img/fondo-bot.png")
      bottom right no-repeat;

  font-size: 1rem;
  line-height: 1.2rem;
  overflow-x: hidden;
  min-height: 100vh;
}
a {
  color: var(--rosa);
  text-decoration: none;
}

h1 {
  font-size: 3.5rem;
  line-height: 3.6rem;
  font-weight: 700;
  color: var(--rosa);
}
h2 {
  font-size: 3.2rem;
  line-height: 3.3rem;
  font-weight: 400;
}
hr {
  width: 60px;
  margin: auto;
}
.btn-primary {
  background-color: var(--rosa);
  color: var(--white);
  border-radius: 0;
  border: none;
  padding: 0.5rem 2rem;
}
.btn-primary:hover {
  background-color: var(--green);
}
/*header*/
header{
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1rem;
}
@media (min-width:576px) {
  header{
    background-color:transparent;
    margin-bottom:0rem;
  }
}

/*programa*/
.box__program {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px 15px 0px 0px;
  min-height: 20px;
}
.location {
  position: absolute;
  top: -60px;
  margin: auto;
  left: 0;
  right: 0;
}
ul.txt__programa {
  font-size: 1.1rem;
  line-height: 1.3rem;
}
ul {
  list-style-image: url("../public/img/bulet.svg");
}
li {
  padding: 0.4rem;
}
/*registro*/
.box__register {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 15px 15px;
  min-height: 200px;
}
.box__top_register {
  background-color: var(--rosa);
  color: var(--white);
  padding: 2.2rem;
  position: relative;
}
.box__top_register .italic {
  font-style: italic;
}
.ball__date {
 position: relative;
  background:var(--green);
  color:var(--white);
  border: 1px solid #ffffff;
  padding: .5rem;
  top:-50px;
border-radius: 15px;

}
.day{
  font-size: 3rem;
  font-weight: 700;
  line-height: 3rem;
}
.month{
  font-size: 1.3rem;
  line-height: 1.3rem;
}
.year{
  font-size: 1.1rem;
  line-height: 1.1rem;
}
.time{
  font-size: 1.8rem;
  font-weight: 700;
  line-height:1.8rem;
}
@media (min-width:576px) {
  .ball__date {
    position: absolute;
    background:var(--green);
    color:var(--white);
    border: 1px solid #ffffff;
    border-radius: 100%;
    width: 155px;
    height: 155px;
    right: -20px;
    top:-100px;
    padding: .5rem;
  
  }
  .day{
    font-size: 3rem;
    font-weight: 700;
    line-height: 3rem;
  }
  .month{
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  .year{
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
  .time{
    font-size: 1.8rem;
    font-weight: 700;
    line-height:1.8rem;
  }
}

footer{
  position: relative;
  background-color: var(--rosa);
  min-height: 20px;
  padding: 1rem;
}
a.nav-links{
  color: var(--white);
}
.logo__foot{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 200px;
}
@media (min-width:576px) {
  .logo__foot{
    width: 300px;
  }
}
@media (min-width:768px) {
  .logo__foot{
    width: auto;
  }
}
.foot_mayo{
  background-color: #fff;
  text-align: center;
  padding: .5rem;
}
.thanks{
 min-height: 320px;
 font-size: 2.5rem;
 line-height: 2.8rem;
 font-weight: 700;
 color:var(--rosa)
}